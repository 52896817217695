import { Body, Button, Col, Grid, GridSettings, H1, Row, Separator } from 'atomic'
import { MatchMedia } from 'atomic/legacy/obj.match-media'
import { graphql } from 'gatsby'
import React from 'react'
import { hasWindow } from 'utils/browser'
import LargeSeparatorRow from 'atomic/atm.large-separator-row/large-separator-row.component'
import SvgErro404 from 'atomic/atm.svg-icon/erro404.component'
import IndexLayout from '../components/org.layout/layout.component'
import { CosmicjsInformacoesEstaticas } from '../data/graphql/graphql-types'
import { PageProps } from '../utils/local-types'
import { pagesPaths } from 'utils/path'
import { isDoctorUrl } from 'utils/url'

interface INotFoundPageProps {}

const NotFoundPage: React.FunctionComponent<PageProps<INotFoundPageProps>> = props => {
  const cosmicData: CosmicjsInformacoesEstaticas = props.data.cosmicjsInformacoesEstaticas
  const handleBackClick = () => {
    if (hasWindow()) {
      window.history.back()
    }
    return null
  }
  return (
    <IndexLayout location={props.location}>
      <Grid>
        <LargeSeparatorRow />

        <Row reverse>
          <Col xs={12} mdOffset={1} md={7}>
            <SvgErro404 width="100%" />
            <MatchMedia defaultMinWidth={`${GridSettings.flexboxgrid.breakpoints.md}em`}>
              {md => md && <Separator />}
            </MatchMedia>
          </Col>

          <Col xs={12} md={4}>
            <Row mb>
              <Col xs={12}>
                <H1>{cosmicData.title}</H1>
                <Body>
                  <Separator />
                  <div dangerouslySetInnerHTML={{ __html: cosmicData.content }} />
                </Body>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <Button id="button-back-404" kind="secondary" expanded onClick={handleBackClick}>
                  {cosmicData.metadata.botaoVoltarTexto}
                </Button>
              </Col>
              <Col xs={6}>
                <Button
                  id="button-404"
                  kind="secondary"
                  expanded
                  to={
                    isDoctorUrl(props.location.pathname)
                      ? pagesPaths.homeDoctor.path
                      : pagesPaths.homeClient.path
                  }
                >
                  {cosmicData.metadata.botaoPaginaInicialTexto}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <LargeSeparatorRow />
      </Grid>
    </IndexLayout>
  )
}

export default NotFoundPage

export const query = graphql`
  query PaginaNaoEncontrada {
    cosmicjsInformacoesEstaticas(slug: { eq: "pagina-nao-encontrada" }) {
      title
      content
      metadata {
        botaoVoltarTexto
        botaoPaginaInicialTexto
      }
    }
  }
`
